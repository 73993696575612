import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import ElementPlus from 'element-plus'
import pinia from '@/stores'
// import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

import '@/assets/css/index.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import "animate.css";



const app = createApp(App)
app.use(router).use(ElementPlus, { size: 'default', locale: zhCn, }).use(pinia).mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
