import { defineStore } from 'pinia'
const { body } = document


let lang = navigator.language;//获取浏览器默认语言。
const language = lang === 'zh-CN' ? 'cn' : 'en'

const useApp = defineStore({
  id: 'app',
  state: () => ({
    WIDTH: 720, // 992
    language: language,
    mobileStatus: false
  }),
  getters: {
    isMobile() {
      return this.getMobile() || this.mobileStatus
    },
    languageStatus() {
      return this.language
    }
  },
  actions: {
    getMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < this.WIDTH
    },
  }
})

export default useApp
