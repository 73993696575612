import { createRouter, createWebHashHistory } from 'vue-router'
const Layout = () => import("@/layout/index.vue");

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/home/index.vue"),
      }
    ],
  },
  {
    path: '/templatePath',
    name: 'Template',
    component: Layout,
    redirect: "/template",
    children: [
      {
        path: "/template",
        name: "Template",
        component: () => import("@/views/davinci/index.vue"),
      },
      {
        path: "/templateDetails/:id",
        name: "TemplateDetails",
        component: () => import("@/views/davinci/details.vue"),
      }
    ],
  },
  {
    path: '/davinciResoleDetails/:id',
    name: 'DavinciResoleDetails',
    component:  () => import("@/views/davinci/davinciResoleDetails.vue"),
    // component: Layout,
    // redirect: "/davinciResoleDetailsChild",
    // children: [
    //   {
    //     path: "/davinciResoleDetailsChild",
    //     name: "DavinciResoleDetailsChild",

    //   },
    // ]
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
  },
  { path: '/:pathMatch(.*)', redirect: '/404'}
]

const router = createRouter({
  scrollBehavior: () => ({ y: 0 }),
  history: createWebHashHistory(),
  routes
})

export default router
